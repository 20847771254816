<template>
  <tr>
    <td>
      <v-chip label small>
        {{ annotationType.name }}
      </v-chip>
    </td>
    <td>
      <v-icon :style="`color: ${annotationType.color}`" class="mr-3">
        {{ annotationType.color ? "brightness_1" : "panorama_fish_eye" }}
      </v-icon>
      {{ annotationType.color }}
    </td>

    <td>
      <v-btn
        icon
        :to="{
          name: 'annotation-types.show',
          params: { annotationTypeId: annotationType.id }
        }"
      >
        <v-icon>far fa-search</v-icon>
      </v-btn>
    </td>
  </tr>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import { FIND_BY_ID } from "@/store/templates/table/getter-types";

const { mapGetters: mapAuthGetters } = createNamespacedHelpers("auth");
const {
  mapGetters: mapAnnotationTypeGetters,
  mapState: mapAnnotationTypeState
} = createNamespacedHelpers("annotationTypes");

export default {
  name: "annotation-type-table-row",
  props: {
    annotationTypeId: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapAuthGetters(["hasRolesOrPermissions"]),
    ...mapAnnotationTypeGetters([FIND_BY_ID]),
    ...mapAnnotationTypeState(["annotationType"]),
    annotationType() {
      return this[FIND_BY_ID](this.annotationTypeId);
    }
  }
};
</script>
