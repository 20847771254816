<template>
  <v-container fluid>
    <v-text-field
      v-model="name"
      v-validate="'required|max:255'"
      label="Name"
      autocomplete="not-my-name"
      :counter="255"
      :error-messages="errors.collect('name')"
      data-vv-name="name"
      required
    />
    <v-text-field v-model="color" type="color" label="Color" />
  </v-container>
</template>

<script type="text/babel">
import { createNamespacedHelpers } from "@/store/helpers";
const { mapComputed } = createNamespacedHelpers("annotationType");

export default {
  name: "annotation-type",
  inject: ["$validator"],
  data: () => ({}),
  computed: {
    ...mapComputed(["name", "color"])
  }
};
</script>
