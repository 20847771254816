<template>
  <v-card color="white">
    <crud-header
      :title="name"
      type="Annotation Type"
      store="annotationType"
      @save-success="
        ({ id }) =>
          $router.push({
            name: 'annotation-types.show',
            params: { annotationTypeId: id }
          })
      "
    />
    <annotation-type />
  </v-card>
</template>

<script type="text/babel">
import annotationTypeModule from "@/store/modules/annotation-type";
import CrudHeader from "../../components/generic/crud/CrudHeader";
import { createNamespacedHelpers } from "vuex";
const { mapGetters } = createNamespacedHelpers("annotationType");
import { mapDetailViewLifecycleMethods } from "../helpers";
import AnnotationType from "../../components/annotation-type/AnnotationType";

const detailViewLifecycleMethods = mapDetailViewLifecycleMethods(
  "annotationType",
  annotationTypeModule
);

export default {
  name: "annotation-type-view",
  $_veeValidate: {
    validator: "new" // give me my own validator scope.
  },
  components: {
    AnnotationType,
    CrudHeader
  },
  computed: {
    ...mapGetters(["name", "color"])
  },
  ...detailViewLifecycleMethods
};
</script>
