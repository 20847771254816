<template>
  <table-overview
    title="Annotation Types"
    store="annotationTypes"
    :headers="[
      { text: 'Name', value: 'name' },
      { text: 'Color', value: 'color' },
      {
        text: 'Actions',
        align: 'center',
        sortable: false,
        class: 'v-table-th--icon',
        rolesOrPermissions: 'annotation-types_view'
      }
    ]"
    :actions="actions"
    force-initial-sort
  >
    <annotation-type-table-row
      slot="table-row"
      slot-scope="{ item }"
      :annotation-type-id="item"
    />
  </table-overview>
</template>
<script type="text/babel">
import TableOverview from "../../components/generic/overview/TableOverview";
import annotationTypesModule from "@/store/modules/annotation-types";
import { mapOverviewLifecycleMethods } from "../helpers";
import AnnotationTypeTableRow from "@/components/annotation-type/AnnotationTypeTableRow";

const overviewLifecycleMethods = mapOverviewLifecycleMethods(
  "annotationTypes",
  annotationTypesModule
);

export default {
  name: "annotation-types-view",
  components: {
    AnnotationTypeTableRow,
    TableOverview
  },
  data: () => ({
    actions: [
      {
        icon: "far fa-fw fa-plus",
        color: "pink",
        to: { name: "annotation-types.create" },
        rolesOrPermissions: "annotation_type_create"
      }
    ]
  }),
  ...overviewLifecycleMethods
};
</script>
