import { render, staticRenderFns } from "./AnnotationTypesView.vue?vue&type=template&id=3508e20a&"
import script from "./AnnotationTypesView.vue?vue&type=script&lang=js&"
export * from "./AnnotationTypesView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports